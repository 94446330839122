import moment from "moment-timezone"
import { useState } from "react"

// -- CONSTANTS
const defaultZone = "America/Chicago"

// -- FUNCTION
const timezoneParser = (dtStr, tz) =>
  moment.tz(dtStr, "MMMM DD, YYYY HH:mm", tz)

// -- MAIN
const useTimezone = () => {
  // ---- sfn only
  /**
   * @description Due to security policy, we cannot set timezone in the cookies
   * in the production because of the different domain
   * - parent: https://www.abstractsonline.com
   * - children (iframe): https://sfn2023.neuromatch.io
   *
   * To workaround this, we will use local state only.
   */
  const [timezone, setTimezone] = useState(defaultZone)

  return {
    timezone,
    setTimezone,
  }
}

export { timezoneParser }
export default useTimezone
